module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-styled-components-virtual-e12a3b8272/0/cache/gatsby-plugin-styled-components-npm-6.11.0-c502b4e601-47de243cc4.zip/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-catch-links-virtual-1b6d328c94/0/cache/gatsby-plugin-catch-links-npm-5.11.0-968e140504-69abe340e2.zip/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-d9691c64fa/0/cache/gatsby-remark-images-npm-7.11.0-1a0bdec2e3-7abbac1ee9.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":970,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-d9691c64fa/0/cache/gatsby-remark-images-npm-7.11.0-1a0bdec2e3-7abbac1ee9.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-autolink-headers-virtual-5b178ceded/0/cache/gatsby-remark-autolink-headers-npm-6.11.0-d6ff80556f-dcd001a765.zip/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-d9691c64fa/0/cache/gatsby-remark-images-npm-7.11.0-1a0bdec2e3-7abbac1ee9.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"backgroundColor":"transparent","linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-4d68e2dfc8/0/cache/gatsby-plugin-manifest-npm-5.11.0-857fd9ff94-785b9c333d.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ASMAN TOGA","short_name":"ASMANTOGA","description":"Taman Asuhan Mandiri Tanaman Obat Keluarga Desa Kalianyar. &copy; 2023.","start_url":"/","background_color":"white","theme_color":"#5183f5","display":"minimal-ui","icon":"static/logo.png","cache_busting_mode":"none","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-offline-virtual-cf3beebc14/0/cache/gatsby-plugin-offline-npm-6.11.0-5ce4664fd4-b783421914.zip/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/logo.png"]}},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-81a2fa3f58/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
